import { SeoHeader } from '@components/Common';
import { CookiePolicy } from '@components/Layouts';
import { Footer, NavigationHeader } from '@components/Navigation';
import { AffiliateInfoBox, ResourceСategoriesMenu } from '@components/Resources';
import { categoryToUri, Resource, ResourceCategory, ResourceTopic } from '@core';
import { Routes, getPostSubcatPageTitle } from '@navigation';
import { mc } from '@styles';
import { clearHtmlTagText, uniqBy } from '@utils';
import { graphql } from 'gatsby';
import parseHtml from 'html-react-parser';
import React, { FC, useState } from 'react';

import SubCategoryTopics from './components/Topics';

interface Props {
  data: { circleof: { resourceFeed: { data: Resource[] } } };
  pageContext: PageContext;
}

interface PageContext {
  category: ResourceCategory;
  categoryId: number;
  subcategory: ResourceCategory;
  subcategoryId: number;
  subcategories: ResourceCategory[];
}

const allTopic: ResourceTopic = { id: -1, name: 'All', color: '', level: -1, categoryId: -1, backgroundColor: '' };

const sortResourcesWithCategoryFeaturedMerchants = (resources: Resource[], category: ResourceCategory): Resource[] => {
  const { featuredMerchantIds } = category;
  if (!featuredMerchantIds || !featuredMerchantIds.length) return resources;
  const getResourceWeight = (r: Resource) => {
    const index = featuredMerchantIds.findIndex(id => id === r.id);
    return index === -1 ? 9999 : index;
  };
  return resources.sort((a, b) => getResourceWeight(a) - getResourceWeight(b));
};

export const SubCategoryPage: FC<Props> = ({ pageContext, data }) => {
  const [selectedTopics, setSelectedTopics] = useState<ResourceTopic[]>([allTopic]);

  const { category, subcategory, subcategories } = pageContext;
  const resources = sortResourcesWithCategoryFeaturedMerchants(data.circleof.resourceFeed.data, subcategory);
  const topics = uniqBy(resources.map(resource => resource.topics).flat(), 'id').filter(itm => itm.categoryId === subcategory.id);

  const getFilteredResources = () => {
    if (!selectedTopics.length) return resources;
    const selectedIds = selectedTopics.map(itm => itm.id);
    if (selectedIds.includes(-1)) return resources;
    return resources.filter(resource => {
      const resourceTopicIds = resource.topics.map(itm => itm.id);
      return resourceTopicIds.some(id => selectedIds.includes(id));
    });
  };

  const handleTopicClick = (itm: ResourceTopic) => {
    if (itm.id === -1) return setSelectedTopics([allTopic]);
    const isSelected = selectedTopics.map(itm => itm.id).includes(itm.id);
    const newSelectedTopics = isSelected ? selectedTopics.filter(topic => topic.id !== itm.id) : [...selectedTopics, itm];
    const newWithoutAll = newSelectedTopics.filter(topic => topic.id !== -1);
    if (newWithoutAll.length) return setSelectedTopics(newWithoutAll);
    else return setSelectedTopics([allTopic]);
  };

  return (
    <>
      <NavigationHeader />
      <CookiePolicy />
      <div>
        <div className="px-4 pt-8 mx-auto w-full max-w-[1168px]">
          <div>
            <a
              className="text-lg font-raleway leading-normal font-medium text-ocean-blue"
              href={categoryToUri(category)}
            >{`${category.name} Home`}</a>
            <span className="text-lg font-raleway leading-normal font-medium text-ocean-blue"> {' / '}</span>
            <span className="text-lg font-raleway leading-normal font-medium text-salmon">{`${subcategory.name}`}</span>
          </div>
          <div className={mc('mt-4 lg:mt-5')}>
            <a
              className={mc('font-raleway leading-normal font-semibold text-sm', 'hover:underline')}
              href={Routes.affiliateDisclosure.url}
            >
              Affiliate Disclosure
            </a>
          </div>
          <h1 className={mc('m-0 mt-5', 'text-5xl font-literata leading-tight font-medium text-ocean-blue')}>
            {subcategory.name}
          </h1>
        </div>
        {!!subcategory.description && (
          <div
            className={mc(
              'mt-8 px-4 mx-auto max-w-[1016px]',
              'font-raleway leading-normal font-medium text-neutral-grey-700',
              'space-y-3',
            )}
          >
            {parseHtml(subcategory.description)}
          </div>
        )}
        <div className="flex flex-row justify-center px-4 py-6 mt-4 mx-auto max-w-[1016px]">
          <ResourceСategoriesMenu
            rootCategory={category}
            items={subcategories}
            active={subcategory}
            color="seafoam"
            linkType="link"
          />
        </div>
        {!!topics.length && (
          <SubCategoryTopics
            className="px-4 py-4 mt-4 mx-auto max-w-[1016px]"
            items={[allTopic, ...topics]}
            selected={selectedTopics}
            onItemClick={handleTopicClick}
          />
        )}
        <div className="px-4 pb-8 mx-auto max-w-[1016px]">
          {getFilteredResources().map(resource => (
            <AffiliateInfoBox key={resource.id} className="py-3 mt-8 border-b" data={resource} selectedTopics={topics} />
          ))}
        </div>
        {/* <div className="px-4 py-10 sm:py-32 mx-auto w-full max-w-[1016px]">
          <h2 className="m-0 text-4xl font-literata leading-tight font-medium text-dark">Frequently Asked Questions</h2>
          <div className="mt-3 text-lg font-raleway leading-normal font-medium text-primary-black-700">
            Get answers to the most frequently asked questions.
          </div>
          <Accordion
            className="mt-8"
            items={[
              {
                title: 'What is a care consultant?',
                content: (
                  <>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus vestibulum lobortis amet tellus, fermentum
                      fermentum. Dis consectetur purus proin lorem mauris. Diam urna nibh pretium, ac. Neque amet suspendisse eget
                      cum scelerisque. Et elementum lacinia pellentesque amet, ut risus ipsum. Consectetur ipsum nec feugiat eu
                      habitasse sagittis, at felis.
                    </p>
                  </>
                ),
              },
              {
                title: 'What are some affordable ways to make my parents home safer?',
                content: (
                  <>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus vestibulum lobortis amet tellus, fermentum
                      fermentum. Dis consectetur purus proin lorem mauris. Diam urna nibh pretium, ac. Neque amet suspendisse eget
                      cum scelerisque. Et elementum lacinia pellentesque amet, ut risus ipsum. Consectetur ipsum nec feugiat eu
                      habitasse sagittis, at felis.
                    </p>
                  </>
                ),
              },
              {
                title: 'When do caregivers need a consultants help?',
                content: (
                  <>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus vestibulum lobortis amet tellus, fermentum
                      fermentum. Dis consectetur purus proin lorem mauris. Diam urna nibh pretium, ac. Neque amet suspendisse eget
                      cum scelerisque. Et elementum lacinia pellentesque amet, ut risus ipsum. Consectetur ipsum nec feugiat eu
                      habitasse sagittis, at felis.
                    </p>
                  </>
                ),
              },
              {
                title: 'What are some affordable ways to make my parents home safer?',
                content: (
                  <>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus vestibulum lobortis amet tellus, fermentum
                      fermentum. Dis consectetur purus proin lorem mauris. Diam urna nibh pretium, ac. Neque amet suspendisse eget
                      cum scelerisque. Et elementum lacinia pellentesque amet, ut risus ipsum. Consectetur ipsum nec feugiat eu
                      habitasse sagittis, at felis.
                    </p>
                  </>
                ),
              },
              {
                title: 'What are some affordable ways to make my parents home safer?',
                content: (
                  <>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus vestibulum lobortis amet tellus, fermentum
                      fermentum. Dis consectetur purus proin lorem mauris. Diam urna nibh pretium, ac. Neque amet suspendisse eget
                      cum scelerisque. Et elementum lacinia pellentesque amet, ut risus ipsum. Consectetur ipsum nec feugiat eu
                      habitasse sagittis, at felis.
                    </p>
                  </>
                ),
              },
            ]}
          />
        </div> */}
      </div>
      <Footer />
    </>
  );
};

export const Head: FC<{ pageContext: PageContext }> = ({ pageContext }) => (
  <SeoHeader
    title={getPostSubcatPageTitle(pageContext.category, pageContext.subcategory)}
    description={clearHtmlTagText(pageContext.subcategory.description || '')}
  />
);

export const query = graphql`
  query ($subcategoryId: Int!) {
    circleof {
      resourceFeed(filter: { isWeb: true }, limit: 500, offset: 0, categoryIds: [$subcategoryId]) {
        data {
          ...ResourceFragment
        }
      }
    }
  }
`;

export default SubCategoryPage;
