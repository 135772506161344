import { ResourceTopic } from '@core';
import { mc, StyleProps } from '@styles';
import React, { FC, MouseEvent } from 'react';

interface Props extends StyleProps {
  items: ResourceTopic[];
  selected?: ResourceTopic[];
  onItemClick?: (item: ResourceTopic) => void;
}

export const SubCategoryTopics: FC<Props> = ({ className, style, items, selected, onItemClick }) => {
  const handleItemClick = (item: ResourceTopic) => (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (onItemClick) onItemClick(item);
  };

  const isSelected = (item: ResourceTopic) => selected && selected.map(itm => itm.id).includes(item.id);

  return (
    <div className={mc('flex flex-row justify-start flex-wrap', className)} style={style}>
      {items.map(itm => (
        <a
          className={mc(
            'mr-2 mb-2 px-4 py-1.5',
            'rounded-full border border-ocean-blue',
            'text-sm font-opensans leading-normal text-center font-semibold',
            !isSelected(itm) && 'bg-white text-ocean-blue hover:bg-ocean-blue hover:text-white',
            isSelected(itm) && 'bg-ocean-blue text-white hover:bg-ocean-blue  hover:text-white',
          )}
          href="#"
          onClick={handleItemClick(itm)}
          key={itm.id}
        >
          {itm.name}
        </a>
      ))}
    </div>
  );
};

export type SubCategoryTopicsProps = Props;
export default SubCategoryTopics;
